const MoonIcon = () => {

    return (
        <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_361_428)">
                <path fill="currentColor" d="M34.8918 64C44.7363 64 53.7646 59.5245 59.7473 52.1531C63.2811 47.7993 59.4276 41.4379 53.9662 42.478C43.6727 44.4384 34.1822 36.5695 34.1822 26.129C34.1822 20.076 37.4397 14.5925 42.6115 11.6495C47.4546 8.89325 46.2363 1.551 40.7337 0.534625C38.8067 0.179268 36.8513 0.000319539 34.8918 0C17.2218 0 2.89185 14.3219 2.89185 32C2.89185 49.67 17.2138 64 34.8918 64ZM34.8918 6C36.515 6 38.103 6.15013 39.6438 6.43475C32.7988 10.3301 28.1822 17.69 28.1822 26.129C28.1822 40.36 41.1373 51.029 55.0887 48.372C50.3217 54.2455 43.0457 58 34.8918 58C20.5325 58 8.89185 46.3594 8.89185 32C8.89185 17.6406 20.5325 6 34.8918 6Z" />
            </g>
            <defs>
                <clipPath id="clip0_361_428">
                    <rect width="64" height="64" fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default MoonIcon;